<template>
<div
  class="eye-container"
  v-if="
    olhoDireitoAdicao ||
    olhoDireitoCilindrico ||
    olhoDireitoDP ||
    olhoDireitoAV ||
    olhoDireitoEixo ||
    olhoDireitoEsferico ||
    olhoEsquerdoAdicao ||
    olhoEsquerdoCilindrico ||
    olhoEsquerdoDP ||
    olhoEsquerdoAV ||
    olhoEsquerdoEixo ||
    olhoEsquerdoEsferico ||
    valoresNaPrescricao ||
    aparelho ||
    olhoDireitoMd ||
    olhoEsquerdoMd ||
    olhoDireitoPsd ||
    olhoEsquerdoPsd ||
    laudoDireito ||
    laudoEsquerdo ||
    observacao ||
    olhoDireito ||
    observacaoDireito ||
    olhoEsquerdo ||
    observacaoEsquerdo
  "  
>
    <div class="container-header">
      <h3>{{ title }}</h3>
      <div class="chevron" @click="handleOpen()" v-bind:class="{ rotateUp: !open, rotateDown: open }">
        <ChevronUp />
      </div>
    </div>

    <div class="values" v-if="this.open" v-bind:class="{ openTable: open, closeTable: !open }">
        <b-row >
          <b-col >
              <b-col  v-if="title === 'Campo visual'">
                <span>{{ aparelho}}</span>
                <div class="custom-input-group" >
                  <div class="custom-input">
                    <div class="eye-area">
                      <EyeFill /> D
                    </div>
                    <p class="text-area flex type-2 with-br">
                      <span>MD</span>
                      <input autocomplete="off" type="text" class="form-control text-center with-btr" :placeholder="olhoDireitoMd" readonly>
                    </p>
                    <p class="text-area flex type-2 with-br">
                      <span>PSD</span>
                      <input autocomplete="off" type="text" class="form-control text-center with-btr" :placeholder="olhoDireitoPsd" readonly>
                    </p>
                    <p class="text-area type-2">
                      <span>Laudo</span>
                      <TextArea
                        id="laudoEsquerdo"
                        :value="laudoDireito"
                        :readonly="true"
                        type="text"
                        class="form-control-text with-bbr"
                      />
                    </p>
                  </div>
                  <div class="custom-input" >
                    <div class="eye-area">
                      <EyeFill /> E
                    </div>
                    <p class="text-area flex type-2 with-br">
                      <input autocomplete="off" type="text" class="form-control text-center with-bbr" :placeholder="olhoEsquerdoMd" readonly >
                    </p>
                    <p class="text-area flex type-2 with-br">
                      <input autocomplete="off" type="text" class="form-control text-center with-bbr" :placeholder="olhoEsquerdoPsd" readonly >
                    </p>
                    <p class="text-area type-2">
                      <TextArea
                        id="laudoEsquerdo"
                        :value="laudoEsquerdo"
                        :readonly="true"
                        type="text"
                        class="form-control-text with-br"
                      />
                    </p>
                  </div>
                </div>
                <div class="text-area type-2 with-br ">
                  <span>Observação</span>
                  <TextArea
                    id="observacao"
                    :value="observacao"
                    :readonly="true"
                    type="text"
                    class="form-control-text with-br"
                  />
                </div>
              </b-col>
              <b-col  v-else-if="title === 'OCT (Tomografia de Coerência Óptica) de Mácula'">
                <div class="custom-input-group" >
                  <div class="custom-input">
                    <div class="eye-area">
                      <EyeFill /> D
                    </div>
                    <p class="text-area flex type-2 with-br">
                      <span>Esp.central foveal</span>
                      <input autocomplete="off" type="text" class="form-control text-center with-btr" :placeholder="olhoDireito" readonly>
                    </p>
                    <p class="text-area flex flex-2 type-2 with-br">
                      <span>Observação</span>
                      <TextArea
                        id="observacaoDireito"
                        :value="observacaoDireito"
                        :readonly="true"
                        type="text"
                        class="form-control-text with-br"
                      />
                    </p>                    
                  </div>
                  <div class="custom-input" >
                    <div class="eye-area">
                      <EyeFill /> E
                    </div>
                    <p class="text-area flex type-2 with-br">
                      <input autocomplete="off" type="text" class="form-control text-center with-bbr" :placeholder="olhoEsquerdo" readonly >
                    </p>
                    <p class="text-area flex flex-2 type-2 with-br">
                      <TextArea
                        id="observacaoEsquerdo"
                        :value="observacaoEsquerdo"
                        :readonly="true"
                        type="text"
                        class="form-control-text with-br"
                      />
                    </p>              
                  </div>
                </div>
              </b-col>

              <b-col cols="12" v-else>
                <div class="custom-input-group margin">
                  <div class="custom-input">
                    <div class="eye-area">
                      <EyeFill /> D
                    </div>
                    <p class="text-area type-2 with-br" v-if="olhoDireitoEsferico || olhoEsquerdoEsferico">
                      <span>Esférico</span>
                      <input autocomplete="off" type="text" class="form-control text-center" :placeholder="this.olhoDireitoEsferico" readonly>
                    </p>
                    <p class="text-area type-2 with-br" v-if="olhoDireitoCilindrico || olhoEsquerdoCilindrico">
                      <span>Cilíndrico</span>
                      <input autocomplete="off" type="text" class="form-control text-center" :placeholder="this.olhoDireitoCilindrico" readonly >
                    </p>
                    <p class="text-area type-2 with-br" v-if="olhoDireitoEixo || olhoEsquerdoEixo">
                      <span>Eixo</span>
                      <input autocomplete="off" type="text" class="form-control text-center" :placeholder="this.olhoDireitoEixo" readonly >
                    </p>
                    <p class="text-area type-2 with-br" v-if="olhoDireitoAdicao || olhoEsquerdoAdicao">
                      <span>Adição</span>
                      <input autocomplete="off" type="text" class="form-control text-center with-btr" :placeholder="olhoDireitoAdicao" readonly>
                    </p>
                    <p class="text-area type-2 with-br" v-if="olhoDireitoAV || olhoEsquerdoAV">
                      <span>AV</span>
                      <input autocomplete="off" type="text" class="form-control text-center with-btr" :placeholder="olhoDireitoAV" readonly >
                    </p>
                    <p class="text-area type-2" v-if="olhoDireitoDP || olhoEsquerdoDP">
                      <span>D.P.</span>
                      <input autocomplete="off" type="text" class="form-control text-center with-btr" :placeholder="olhoDireitoDP" readonly >
                    </p>
                  </div>
                  <div class="custom-input no-bb">
                    <div class="eye-area">
                      <EyeFill /> E
                    </div>
                    <p class="text-area type-2 with-br" v-if="olhoDireitoEsferico || olhoEsquerdoEsferico">
                      <input autocomplete="off" type="text" class="form-control text-center" :placeholder="olhoEsquerdoEsferico" readonly >
                    </p>
                    <p class="text-area type-2 with-br" v-if="olhoDireitoCilindrico || olhoEsquerdoCilindrico">
                      <input autocomplete="off" type="text" class="form-control text-center" :placeholder="olhoEsquerdoCilindrico" readonly >
                    </p>
                    <p class="text-area type-2 with-br" v-if="olhoDireitoEixo || olhoEsquerdoEixo">
                      <input autocomplete="off" type="text" class="form-control text-center" :placeholder="olhoEsquerdoEixo" readonly >
                    </p>
                    <p class="text-area type-2 with-br" v-if="olhoDireitoAdicao || olhoEsquerdoAdicao">
                      <input autocomplete="off" type="text" class="form-control text-center with-bbr" :placeholder="olhoEsquerdoAdicao" readonly >
                    </p>
                    <p class="text-area type-2 with-br"  v-if="olhoDireitoAV || olhoEsquerdoAV">
                      <input autocomplete="off" type="text" class="form-control text-center with-bbr" :placeholder="olhoEsquerdoAV" readonly >
                    </p>
                    <p class="text-area type-2"  v-if="olhoDireitoDP || olhoEsquerdoDP">
                      <input autocomplete="off" type="text" class="form-control text-center with-bbr" :placeholder="olhoEsquerdoDP" readonly >
                    </p>
                  </div>
                </div>
                <!-- Info abaixo somente para migrações -->
                <span v-if="observacao">Observação: </span> {{ observacao }}
              </b-col>

          <b-row v-if="valoresNaPrescricao">
            <b-col>
              <label for="usar-valores-refracao" class="checkbox">
                <input type="checkbox" id="usar-valores-refracao" checked disabled readonly/>
                <span class="checkmark checkmark-disabled">
                  <Check />
                </span>
                Usar valores na prescrição de óculos
              </label>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
</div>
</template>

<script>

export default {
  components: { 
    Check: () => import('@/assets/icons/check.svg'),
    ChevronUp: () => import('@/assets/icons/chevron-up.svg'),
    EyeFill: () => import('@/assets/icons/eye-fill.svg'), 
    TextArea: () => import('@/components/General/TextArea'),

 },
    props: {
        title: String,
        olhoDireitoAdicao: String,
        olhoDireitoCilindrico: String,
        olhoDireitoDP: String,
        olhoDireitoAV: String,
        olhoDireitoEixo: String,
        olhoDireitoEsferico: String,
        olhoEsquerdoAdicao: String,
        olhoEsquerdoCilindrico: String,
        olhoEsquerdoDP: String,
        olhoEsquerdoAV: String,
        olhoEsquerdoEixo: String,
        olhoEsquerdoEsferico: String,
        valoresNaPrescricao: Boolean,
        aparelho: String,
        olhoDireitoMd: String,
        olhoEsquerdoMd: String,
        olhoDireitoPsd: String,
        olhoEsquerdoPsd: String,
        laudoDireito: String,
        laudoEsquerdo: String,
        observacao: String,
        olhoDireito: String,
        observacaoDireito: String,
        olhoEsquerdo: String,
        observacaoEsquerdo: String
    },

    data() {
      return{
        open: true,
      }
    },

    methods: {
      handleOpen(){
        this.open = !this.open
      }
    },
}
</script>

<style lang="scss" scoped>
    .eye-container {
        display: flex;
        flex-direction: column;
        margin: 20px 0;

        .container-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 30px;

    h3 {
      font-size: 18px;
      color: var(--dark-blue);
      font-weight: 600;
    }

    .chevron {
      width: 25px;
      height: 25px;

      > svg {
        cursor: pointer;
        stroke: var(--neutral-600);
        transition: .5s;
      }
    }

    .rotateUp {
        animation: rotateUp .5s;
        -webkit-animation-fill-mode: forwards;
        -moz-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
    }

    .rotateDown {
        animation: rotateDown .5s;
        -webkit-animation-fill-mode: forwards;
        -moz-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
    }

    @keyframes rotateUp {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(180deg);
      }
    }

    @keyframes rotateDown {
      0% {
        transform: rotate(180deg);
      }
      100% {
        transform: rotate(0deg);
      }
    }

  }

        h4 {
            color: var(--dark-blue);
            font-weight: 600;
            font-size: 18px;
        }


        .custom-input-group {
            display: flex;
            flex-direction: column;
            border: 1px solid var(--neutral-300);
            border-radius: 8px;
            margin: 24px 0 16px 0;

            &.margin {
              margin: 24px 25% 16px 0px;
            }

        .custom-input {
          flex: 1;
          border: 8px;
          display: flex;
          flex-direction: row;
          border-bottom: 1px solid var(--neutral-300);
          justify-content: center;

          input[readonly] {
              background-color: var(--neutral-100);
          }

          &.no-bb {
            border-bottom: 0;

            .eye-area {
              border-radius: 0 0 0 8px;
            }
          }

          .eye-area {
            width: 60px;
            background-color: var(--neutral-100);
            border-right: 1px solid var(--neutral-300);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 8px 0 0 0;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;

            svg {
              width: 16px;
              height: 16px;
              fill: var(--type-active);
              margin-right: 4px;
            }
          }

          .text-area {
            flex: 1;
            background-color: var(--neutral-100);
            justify-content: center;
            align-items: center;
            line-height: 55px;
            margin-left: 16px;
            font-weight: 400;
            font-size: 16px;
            color: var(--type-active);

            &.flex-2{
              flex: 2 !important;
            }

            .form-control {
              border: 0 !important;
              border-radius: 0 !important;
              padding: 10px 10px !important;

              &.with-bbr {
                border-radius: 0 0 8px 0 !important;
              }

              &.with-btr {
                border-radius: 0 8px 0 0 !important;
              }
            }
            &.flex{
              display: flex;
            }
            &.type-2 {
              position: relative;
              margin-left: 0;
              text-align: center;

              .form-control-text{
                background-color: var(--neutral-100);
              }
              span {
                width: 100%;
                position: absolute;
                top: -19px;
                left: 0;
                font-weight: 700;
                font-size: 12px;
                line-height: 16px;
                color: var(--type-active);
              }
            }

            &.with-br {
              border-right: 1px solid var(--neutral-300) !important;
              margin-bottom: 0px !important;
            }

            &.with-brtr {
              border-radius: 0 8px 0 0 !important;
            }

            &.with-brbr {
              border-radius: 0 0 8px 0 !important;
            }
          }
        }
      }

    }
</style>
